import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {  setAuthenticating, setError } from '../redux/readerSlice';

const useAuth0Logout = () => {
    const dispatch = useDispatch();
    const { logout } = useAuth0();
    const user = useSelector(state => state.reader.user);

    const handleLogout = () => {
        if (user?.signed_in) {
            dispatch(setAuthenticating(true));
            fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
                .then(r => {
                    if (!r.ok) {
                        throw new Error();
                    }
                    return r;
                })
                .then(() => {
                    logout({
                        logoutParams: { returnTo: window.location.origin }
                    });
                })
                .catch(() => {
                    dispatch(setError(true));
                });
        }
    };
    return { handleLogout };
};

export default useAuth0Logout;
