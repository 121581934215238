import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './Dashboard';
import PageLoader from './PageLoader';
import ErrorPage from './ErrorPage';
import Home from './Home';
import useHubspotTracking from '../hooks/useHubspotTracking';

const Main = () => {
    const { isLoading: auth0Loading } = useAuth0();
    const error = useSelector(state => state.reader?.error);
    const reader_initialized = useSelector(state => state.reader?.initialized);
    const is_authenticating = useSelector(
        state => state.reader?.is_authenticating
    );

    useHubspotTracking();

    if (!reader_initialized || auth0Loading || is_authenticating)
        return <PageLoader />;
    if (error) return <ErrorPage />;

    return (
        <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`:slug/:section?`} element={<Dashboard />} />
        </Routes>
    );
};

export default Main;
