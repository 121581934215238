import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderComponent = ({ children }) => (
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_MANAGEMENT_API
        }}
        scope="openid"
    >
        {children}
    </Auth0Provider>
);

export default Auth0ProviderComponent;
