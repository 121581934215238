import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import useAuth0Logout from '../../hooks/useAuth0Logout';

import HeaderLoader from './HeaderLoader';

const HeaderLogInOut = () => {
    const { loginWithPopup, isLoading: auth0Loading } = useAuth0();
    const { handleLogout } = useAuth0Logout();
    const is_authenticating = useSelector(
        state => state.reader?.is_authenticating
    );
    const signed_in = useSelector(state => state?.reader?.user?.signed_in);

    if (auth0Loading || is_authenticating) return <HeaderLoader />;

    return (
        <div className='flex items-center'>
            {signed_in ? (
                <>
                    <span
                        className='text-base sm:text-sm flex items-center cursor-pointer'
                        onClick={() => handleLogout()}
                    >
                        <span className='font-semibold material-symbols-outlined'>
                            person
                        </span>
                        Logout
                    </span>
                    <span className='text-base sm:text-sm px-1'>|</span>
                    <a
                        className='text-base sm:text-sm'
                        href='https://my.skift.com'
                    >
                        Account
                    </a>
                </>
            ) : (
                <span
                    className='text-base sm:text-sm flex items-center cursor-pointer'
                    onClick={() => loginWithPopup()}
                >
                    <span className='font-semibold material-symbols-outlined'>
                        person
                    </span>
                    Login
                </span>
            )}
        </div>
    );
};

export default HeaderLogInOut;
